import React, { useEffect, useMemo, useState } from 'react';
import NextImage from '@/components/public/NextImage';
import Header from '@/components/public/Layout/components/Header';
import NewHeader from '@/reconstruction/components/public/Layout/components/Header';
import NewNavBar from '@/reconstruction/components/public/Layout/components/NavBar';
import styles from './index.module.less';
import Link from 'next/link';
import { QRCode } from 'antd';
import { useAppSelector } from '@/store/hook';
// import { addHrefEventListener } from '@/utils';
import intl from 'react-intl-universal';
import c from 'classnames';

interface Props {
    downloadList: any[];
    globalData?: any;
}

const PCLayout: React.FC<Props> = (props: any) => {
    const currentLocale = intl.getInitOptions().currentLocale || 'zh';
    const { imgPrefix, topHeaderHeight } = useAppSelector((state: any) => {
        return state.config;
    });

    const { downloadList = [], globalData = {} } = props;
    const { baseConfig = {}, menus = [] } = globalData;
    const [QRCodeLink, setQRCodeLink] = useState<string>('');

    useEffect(() => {
        setQRCodeLink(`${window?.location?.origin}/app` || '');
    }, []);

    const DownloadWrapper = ({ children, dataSource }: any) => {
        if (!dataSource.link) {
            return <div className={styles.button__wrapper}>{children}</div>;
        }
        if (dataSource.type === 'link') {
            return (
                <Link href={dataSource.link}>
                    <a className={styles.button__wrapper} target="_blank">
                        {children}
                    </a>
                </Link>
            );
        } else {
            return (
                <Link href={dataSource.link}>
                    <div className={styles.button__wrapper}>{children}</div>
                </Link>
            );
        }
    };

    /** 是否靠右布局 */
    const showRightLayout = useMemo(() => {
        return ['ja'].includes(currentLocale);
    }, [currentLocale]);

    return (
        <div className={styles.wrapper} style={{ paddingTop: topHeaderHeight }}>
            {baseConfig?.showNewLayout ? (
                <NewHeader menus={menus} isApp={true} logo={baseConfig.logo} download={baseConfig.download} />
            ) : (
                <Header menus={menus} isApp={true} logo={baseConfig.logo} download={baseConfig.download} />
            )}
            <div
                className={c(styles.bg1, currentLocale === 'en' ? styles.bg1_en : '')}
                style={{ top: topHeaderHeight }}
            >
                <NextImage layout="fill" src="/download/bg_1.webp" needPrefix alt="background" />
            </div>
            <div className={c(styles.main__wrapper, { [styles.right__layout]: showRightLayout })}>
                <div className={c(styles.main__content, currentLocale === 'en' ? styles.main__content_en : '')}>
                    <div className={c(styles.content__title1, currentLocale === 'en' ? styles.content__title1_en : '')}>
                        <span>{intl.get('App.Title1').d('思考多一点')}</span>
                        <em className={styles.bg3} />
                    </div>
                    <div className={c(styles.content__title2, currentLocale === 'en' ? styles.content__title2_en : '')}>
                        {intl.get('App.Title2').d('洞见远一点')}
                    </div>
                    <p
                        className={styles.content__tips1}
                        dangerouslySetInnerHTML={{ __html: intl.get('App.Tips1'.d('扫码下载Hawk Insight app')) }}
                    />
                    <p className={styles.content__tips2}>{intl.get('App.Tips2').d('或通过安装包下载')}</p>
                    <div className={styles.content__download}>
                        <div className={styles.download__ORCode}>
                            {!!QRCodeLink && (
                                <QRCode
                                    size={210}
                                    value={QRCodeLink}
                                    bordered={false}
                                    icon={`${imgPrefix}/download/QRCode.webp`}
                                />
                            )}
                        </div>
                        <div className={styles.download__button}>
                            {downloadList.map((item: any = {}) => {
                                return (
                                    <DownloadWrapper key={item.icon} dataSource={item}>
                                        <div className={styles.picture}>
                                            <NextImage
                                                src={`/download/${item.icon}.webp`}
                                                needPrefix
                                                alt={item.icon}
                                                width={43}
                                                height={43}
                                            />
                                        </div>
                                        <div className={styles.button__text}>
                                            <p>{item.text[0]}</p>
                                            <p>{item.text[1]}</p>
                                        </div>
                                        <div className={styles.download}>
                                            <NextImage
                                                src="/download/download.webp"
                                                needPrefix
                                                alt="download"
                                                width={24}
                                                height={24}
                                            />
                                        </div>
                                    </DownloadWrapper>
                                );
                            })}
                        </div>
                    </div>

                    <div className={styles.bg2}>
                        <NextImage layout="fill" src="/download/bg_2.webp" needPrefix alt="background" isLang />
                    </div>
                </div>
            </div>

            <NewNavBar menus={menus} />
        </div>
    );
};

export default PCLayout;
