import React, { useState, useRef, useEffect } from 'react';
import { withGlobalData, resizeObserverFun, PAGE_CENTER_WA, PAGE_MOBILE_W } from '@/utils';
import styles from './index.module.less';
import { PCLayout, H5Layout } from '@/components/app';
import { useLogin } from '@/reconstruction/components/hook';

const App = (props: any) => {
    const domRef = useRef<any>(null);
    const [domtWidth, setDomWidth] = useState<number>(PAGE_CENTER_WA);
    useEffect(() => {
        resizeObserverFun(domRef.current, setDomWidth);
    }, []);
    const { globalData = {} } = props;
    const { baseConfig = {} } = globalData;
    const { download = {} } = baseConfig;

    // ------------------ 登录 ------------------
    const { onLogin, LoginModal } = useLogin();

    useEffect(() => {
        if (!window) {
            return;
        }
        (window as any).onLogin = onLogin;
    }, []);
    // ------------------ 登录 ------------------

    const downloadList = [
        {
            text: ['Hawk Insight', 'Google Play'],
            icon: 'icon-google',
            link: download.google_url || '',
            type: 'link',
        },
        {
            text: ['Hawk Insight', 'App Store'],
            icon: 'icon-ios',
            link: download.ios_url || '',
            type: 'link',
        },
        {
            text: ['Hawk Insight', 'APK'],
            icon: 'icon-android',
            link: download.apk_url || '',
            type: 'apk',
        },
    ];

    return (
        <div ref={domRef} className={styles.wrapper}>
            {domtWidth > PAGE_MOBILE_W ? (
                <PCLayout {...props} downloadList={downloadList} />
            ) : (
                <H5Layout {...props} downloadList={downloadList} />
            )}
            {LoginModal}
        </div>
    );
};

export const getServerSideProps = withGlobalData(async () => {
    return { props: {} };
});

App.getLayout = (page: any) => <>{page}</>;

export default App;
