import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import { IsAndroid, currentLocaleUrl } from '@/utils';
import NextImage from '@/components/public/NextImage';
import c from 'classnames';
import intl from 'react-intl-universal';

interface Props {
    globalData?: any;
}

const H5Layout: React.FC<Props> = (props: any) => {
    const currentLocale = intl.getInitOptions().currentLocale;
    const router = useRouter();
    const { globalData = {} } = props;
    const { baseConfig = {} } = globalData;
    const { download = {} } = baseConfig;

    const downloadLink = useMemo(() => {
        const is_android = IsAndroid();
        if (is_android) {
            return download.google_url || '';
        } else {
            return download.ios_url || '';
        }
    }, [download]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.header__wrapper}>
                <em
                    className={c(styles.icon, 'iconfont', 'icon-arrow-left')}
                    onClick={() => {
                        router.back();
                    }}
                />
            </div>
            <div className={styles.bg1}>
                <NextImage layout="fill" src="/download/bg1__mobie.webp" needPrefix alt="background" />
            </div>
            <div className={styles.main__wrapper}>
                <div className={styles.logo}>
                    <NextImage src="/download/logo.webp" needPrefix width={141} height={20} alt="logo" />
                </div>
                <div className={styles.content__title1}>
                    <span>
                        {intl.get('App.Title1').d('思考多一点')}
                        <em className={c(styles.bg__circle, styles[`bg__circle_${currentLocale}`])} />
                    </span>
                </div>
                <div className={c(styles.content__title2, styles[`content__title_${currentLocale}`])}>
                    {intl.get('App.Title2').d('洞见远一点')}
                </div>
                <div className={styles.bg2}>
                    <NextImage layout="fill" src="/download/bg2__mobie.webp" needPrefix alt="background" />
                </div>
                {downloadLink ? (
                    <a href={currentLocaleUrl(downloadLink)} target="_blank" className={styles.download__button}>
                        {intl.get('App.DownloadBtn').d('立即下载')}
                    </a>
                ) : (
                    <div className={styles.download__button}>{intl.get('App.DownloadBtn').d('立即下载')}</div>
                )}
            </div>
        </div>
    );
};

export default H5Layout;
